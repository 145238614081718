import "./Title.css";

export default function Title({ title4, title2 }) {
  return (
    <div className="title">
      <h4>{title4}</h4>
      <h2>{title2}</h2>
    </div>
  );
}
