import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./BaseServiceScreen.css";
import Title from "../../components/title/Title";
import { Fade, Roll, Zoom } from "react-reveal";
import { keyboard } from "@testing-library/user-event/dist/keyboard";

export default function BaseServiceScreen() {
  const { pathname } = useLocation();
  const [lastSegment, setLastSegment] = useState(null);
  const [activeType, setActiveType] = useState(null);

  // Define your routes with potential content values
  const routes = {
    "website-development": {
      content: {
        title: "Website Development",
        description:
          "From sleek, responsive designs to fully functional e-commerce platforms, I build websites that not only look great but also deliver an outstanding user experience and meet your business objectives.",
        types: [
          {
            name: "Personal Blog",
            moreInfo:
              "Development Cost: From 25,000 KES, Hosting Cost: 6,000 KES, Company Email Cost: 4,000 KES",
          },
          {
            name: "Business Website",
            moreInfo:
              "Development Cost: From 30,000 KES, Hosting Cost: 6,500 KES, Company Email Cost: 4,000 KES",
          },
          {
            name: "E-commerce Website",
            moreInfo:
              "Development Cost: From 60,000 KES, Hosting Cost: 10,000 KES, Company Email Cost: 4,000 KES",
          },
          {
            name: "Portfolio Website",
            moreInfo:
              "Development Cost: From 25,000 KES, Hosting Cost: 6,000 KES, Company Email Cost: 4,000 KES",
          },
          {
            name: "News/Magazine Website",
            moreInfo:
              "Development Cost: From 40,000 KES, Hosting Cost: 7,000 KES, Company Email Cost: 4,000 KES",
          },
          {
            name: "Social Media Platform",
            moreInfo:
              "Development Cost: Schedule a call for pricing, Company Email Cost: 4,000 KES",
          },
          {
            name: "Educational Website",
            moreInfo:
              "Development Cost: From 30,000 KES, Hosting Cost: 6,500 KES, Company Email Cost: 4,000 KES",
          },
          {
            name: "Forum/Community Website",
            moreInfo:
              "Development Cost: From 40,000 KES, Hosting Cost: 7,000 KES, Company Email Cost: 4,000 KES",
          },
          {
            name: "Booking/Reservation System",
            moreInfo:
              "Development Cost: From 50,000 KES, Hosting Cost: 8,000 KES, Company Email Cost: 4,000 KES",
          },
          {
            name: "Real Estate Website",
            moreInfo:
              "Development Cost: From 60,000 KES, Hosting Cost: 9,000 KES, Company Email Cost: 4,000 KES",
          },
          {
            name: "Other",
            moreInfo:
              "Pricing will be discussed based on specific requirements.",
          },
        ],
      },
    },
    "mobile-development": {
      content: {
        title: "Mobile App Development",
        description:
          "Create seamless and engaging mobile experiences with custom iOS and Android apps. I design and develop mobile applications that meet your business needs and provide a superior user experience.",
        types: [
          {
            name: "Basic Mobile App",
            moreInfo:
              "Development Cost: From 100,000 KES, Includes essential features like user login, basic functionality, and minimal customization.",
          },
          {
            name: "Business Mobile App",
            moreInfo:
              "Development Cost: From 130,000 KES, Includes advanced features like integrated payment systems, user management, and custom functionalities.",
          },
          {
            name: "E-commerce Mobile App",
            moreInfo:
              "Development Cost: From 150,000 KES, Provides full e-commerce capabilities including product listings, shopping cart, and secure checkout.",
          },
          {
            name: "Social Networking App",
            moreInfo:
              "Development Cost: From 180,000 KES, Features include user profiles, messaging, and social interactions.",
          },
          {
            name: "Educational Mobile App",
            moreInfo:
              "Development Cost: From 140,000 KES, Includes features like interactive content, quizzes, and progress tracking.",
          },
          {
            name: "On-Demand Service App",
            moreInfo:
              "Development Cost: From 200,000 KES, Complex features such as real-time tracking, service booking, and user reviews.",
          },
          {
            name: "Custom Mobile App Development",
            moreInfo:
              "Pricing will be discussed based on specific requirements and features needed.",
          },
        ],
      },
    },
    "machine-learning": {
      content: {
        title: "Machine Learning",
        description:
          "Harness the power of data with machine learning solutions that provide actionable insights and drive smarter decision-making. I develop custom algorithms and models tailored to your specific needs.",
        types: [
          {
            name: "Predictive Analytics Model",
            moreInfo:
              "Development Cost: From 100,000 KES, Provides forecasts based on historical data to predict future trends.",
          },
          {
            name: "Recommendation System",
            moreInfo:
              "Development Cost: From 120,000 KES, Recommends products or content based on user behavior and preferences.",
          },
          {
            name: "Natural Language Processing (NLP) Model",
            moreInfo:
              "Development Cost: From 150,000 KES, Analyzes and understands human language for tasks like sentiment analysis or text classification.",
          },
          {
            name: "Computer Vision Model",
            moreInfo:
              "Development Cost: From 200,000 KES, Processes and interprets visual data from images or video for tasks like object detection.",
          },
          {
            name: "AI Chatbot Development",
            moreInfo:
              "Development Cost: From 100,000 KES, Provides automated customer support and interaction through natural language understanding.",
          },
          {
            name: "Custom Machine Learning Solution",
            moreInfo:
              "Pricing will be discussed based on specific requirements and complexity of the solution.",
          },
        ],
      },
    },
    "digital-marketing": {
      content: {
        title: "Digital Marketing",
        description:
          "Elevate your brand with comprehensive digital marketing strategies that increase online visibility, engage your audience, and drive conversions. From SEO to social media campaigns, I help you connect with your customers.",
        types: [
          {
            name: "SEO Optimization",
            moreInfo:
              "Service Cost: From 50,000 KES, Includes on-page and off-page SEO strategies to improve search engine rankings.",
          },
          {
            name: "Social Media Management",
            moreInfo:
              "Service Cost: From 60,000 KES, Manages and optimizes your social media profiles and campaigns to boost engagement and reach.",
          },
          {
            name: "Content Marketing",
            moreInfo:
              "Service Cost: From 70,000 KES, Develops and distributes valuable content to attract and retain customers.",
          },
          {
            name: "Pay-Per-Click (PPC) Campaigns",
            moreInfo:
              "Service Cost: From 80,000 KES, Manages targeted advertising campaigns on platforms like Google Ads and social media.",
          },
          {
            name: "Email Marketing",
            moreInfo:
              "Service Cost: From 40,000 KES, Creates and manages email campaigns to nurture leads and engage with your audience.",
          },
          {
            name: "Influencer Marketing",
            moreInfo:
              "Service Cost: From 90,000 KES, Connects with influencers to promote your brand and reach a broader audience.",
          },
          {
            name: "Comprehensive Digital Marketing Strategy",
            moreInfo:
              "Service Cost: From 100,000 KES, Develops a complete digital marketing strategy tailored to your business goals.",
          },
        ],
      },
    },
  };

  // Split the path into segments
  const pathSegments = pathname.split("/").filter(Boolean);

  useEffect(() => {
    if (pathSegments.length > 0) {
      const segment = pathSegments[pathSegments.length - 1];
      setLastSegment(segment);

      // Set the first type as active by default
      const currentRoute = routes[segment]?.content;
      if (currentRoute && currentRoute.types.length > 0) {
        setActiveType(currentRoute.types[0]);
      }
    }
  }, [pathname]); // Changed from [pathSegments] to [pathname]

  if (lastSegment === null) {
    return null; // Avoid rendering until the path is determined
  }

  const currentRouteContent = routes[lastSegment]?.content || { types: [] };
  const otherRoutes = Object.keys(routes).filter(
    (route) => route !== lastSegment
  );

  const handleTypeClick = (type) => {
    setActiveType(type);
  };

  const projectsSection = () => (
    <div className="service-sections">
      <Zoom>
        <div className="service-1">
          <div className="row">
            <div className="more-info-action text-center d-flex flex-column justify-content-evenly align-items-center col-lg-4">
              <h5>{activeType.name}</h5>
              {activeType && (
                <div className="more-info">
                  <p>{activeType.moreInfo}</p>
                </div>
              )}
              <a
                href="https://calendly.com/wansenziroz/custom-solution"
                target={"_blank"}
                rel={"norefere"}
                className="action-call"
              >
                Book a call
              </a>
            </div>
            <div className="types col-lg-8">
              {currentRouteContent.types.map((type, index) => (
                <button
                  key={index}
                  className={`type-button ${
                    activeType?.name === type.name ? "active" : ""
                  }`}
                  onClick={() => handleTypeClick(type)}
                >
                  {type.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      </Zoom>
      <div className="row routes justify-content-center">
        <Fade right>
          <div className="route service-2 col-lg-4 d-flex justify-content-between">
            <Link to={`/services/${otherRoutes[0]}`} className="route-link">
              {otherRoutes[0].replace("-", " ")}
            </Link>
          </div>
        </Fade>
        <Roll>
          <div className="route service-3 col-lg-4">
            <Link to={`/services/${otherRoutes[1]}`} className="route-link">
              {otherRoutes[1].replace("-", " ")}
            </Link>
          </div>
        </Roll>
        <Fade right>
          <div className="route service-4 col-lg-4 d-flex justify-content-between">
            <Link to={`/services/${otherRoutes[2]}`} className="route-link">
              {otherRoutes[2].replace("-", " ")}
            </Link>
          </div>
        </Fade>
      </div>
    </div>
  );

  return (
    <div className="mt-4">
      <div className="base-services">
        <div className="container">
          <div className="service-overview d-flex justify-content-center align-items-center flex-column text-center">
            <Fade down>
              <div>
                <Title
                  title2={currentRouteContent.title}
                  title4={"Services I offer"}
                />
                <p>{currentRouteContent.description}</p>
              </div>
            </Fade>
          </div>
          <div className="service-categories">
            <div className="row d-flex justify-content-evenly">
              {projectsSection()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
