import React from "react";
import { Fade } from "react-reveal";
import Title from "../../../../components/title/Title";
import "./Featured.css";
import code from "../../../../assets/images/code_rhythm_icon.png";
import gokijany from "../../../../assets/images/gokijany.png";
import hairyblo from "../../../../assets/images/hairyblo.png";
import vibe from "../../../../assets/images/vibesandvent.png";
import { a } from "react-router-dom";

export default function Featured() {
  return (
    <div className="featured">
      <div className="container">
        <Title title2={"Featured Projects"} title4={"Projects"} />
        <Fade left>
          <a
            href="https://coderhythm.electrixitaty.co/"
            rel="norefere"
            target={"_blank"}
            className="row d-flex featured-project justify-content-center"
          >
            <div className="featured-content col-lg-5 ">
              <h2>Code Rhythm</h2>
              <p>
                Code Rhythm is an educational website that provides detailed
                information about courses, pricing, and an online enrollment
                process for aspiring coders. The platform features an extensive
                list of courses, an online application form, and secure payment
                options, allowing users to easily enroll and begin their
                learning journey.
              </p>
              <p>
                <span>Type</span>: Educational Website, E-Learning Platform
              </p>
            </div>
            <div className="featured-div col-lg-6 d-flex justify-content-center align-items-center">
              <h2
                class="background-image-text"
                style={{ backgroundImage: `url(${code})` }}
              >
                01
              </h2>
            </div>
          </a>
        </Fade>
        <Fade right>
          <a
            href="https://gokijany.electrixitaty.co/"
            rel="norefere"
            target={"_blank"}
            className="row d-flex flex-column-reverse flex-lg-row featured-project justify-content-center"
          >
            <div className="featured-div col-lg-6 d-flex justify-content-center align-items-center">
              <h2
                class="background-image-text"
                style={{ backgroundImage: `url(${gokijany})` }}
              >
                02
              </h2>
            </div>
            <div className="featured-content col-lg-5">
              <h2>Gokijany</h2>
              <p>
                Gokijany is a mobile application that allows users to buy
                eco-friendly products, earn carbon credits, and support climate
                projects. The app includes features for carbon offset tracking,
                donations for tree planting, machine learning-based
                recommendations, and a marketplace for green products.
                <p>
                  <span>Type</span>: Mobile Application, E-Commerce Platform,
                  Crowdfunding Platform
                </p>
              </p>
            </div>
          </a>
        </Fade>
        <Fade left>
          <a
            href="https://vibesandvent.vercel.app/"
            rel="norefere"
            target={"_blank"}
            className="row d-flex featured-project justify-content-center"
          >
            <div className="featured-content col-lg-5">
              <h2>Vibes and Vent</h2>
              <p>
                Vibes and Vent is an online therapy platform connecting clients
                with therapists and providing a comprehensive business
                management system. Features include a directory of therapists,
                appointment booking, client activity tracking, payment
                management, and secure note-taking for therapy sessions.
              </p>
              <p>
                <span>Type</span>
                Online Platform, Therapy Management System, Business Management
                Tool
              </p>
            </div>
            <div className="featured-div col-lg-6 d-flex justify-content-center align-items-center">
              <h2
                class="background-image-text"
                style={{ backgroundImage: `url(${vibe})` }}
              >
                03
              </h2>
            </div>
          </a>
        </Fade>
        <Fade right>
          <a
            href="https://hairyblo.vercel.app/"
            rel="norefere"
            target={"_blank"}
            className="row d-flex featured-project flex-column-reverse flex-lg-row justify-content-center"
          >
            <div className="featured-div col-lg-6 d-flex justify-content-center align-items-center">
              <h2
                class="background-image-text"
                style={{ backgroundImage: `url(${hairyblo})` }}
              >
                04
              </h2>
            </div>
            <div className="featured-content col-lg-5">
              <h2>Hairyblo</h2>
              <p>
                Hairyblo is a tourism platform offering curated information on
                activities, destinations, travel tips, and user reviews to
                enhance travel experiences. The platform provides
                location-specific guides, travel hacks, package deals, videos,
                and comprehensive tips for planning trips and exploring new
                places.
              </p>
              <p>
                <span>Type</span>: Online Platform, Therapy Management System,
                Business Management Tool Tourism Website, Travel Information
                Platform
              </p>
            </div>
          </a>
        </Fade>
      </div>
    </div>
  );
}
