import React from "react";
import "./Overview.css";
import Title from "../../../../components/title/Title";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

export default function Overview() {
  return (
    <div className="overview">
      <div className="container">
        <Title title4="Overview" title2="Overview Of Services I Offer" />
      </div>
      <div className="row mt-4 overview-services d-flex justify-content-lg-between justify-content-around">
        <Fade left>
          <div className="col-lg-3 overview-service">
            <Link
              to={"/services/website-development"}
              className="col-lg-3 overview-service"
              style={{
                backgroundImage:
                  "url(https://images.pexels.com/photos/39284/macbook-apple-imac-computer-39284.jpeg?auto=compress&cs=tinysrgb&w=800)",
              }}
            >
              <div className="overview-content">
                <h2>Web Development</h2>
                <p></p>
              </div>
            </Link>
          </div>
        </Fade>

        <Fade down>
          <div className="col-lg-3 overview-service">
            <Link
              to={"/services/mobile-development"}
              className="col-lg-3 overview-service"
              style={{
                backgroundImage:
                  "url(https://images.pexels.com/photos/147413/twitter-facebook-together-exchange-of-information-147413.jpeg?auto=compress&cs=tinysrgb&w=800)",
              }}
            >
              <div className="overview-content">
                <h2>Mobile App Development</h2>
                <p></p>
              </div>
            </Link>
          </div>
        </Fade>

        <Fade up>
          <div className="col-lg-3 overview-service">
            <Link
              to={"/services/machine-learning"}
              className="col-lg-3 overview-service"
              style={{
                backgroundImage:
                  "url(https://images.pexels.com/photos/159298/gears-cogs-machine-machinery-159298.jpeg?auto=compress&cs=tinysrgb&w=800)",
              }}
            >
              <div className="overview-content">
                <h2>Machine Learning</h2>
                <p></p>
              </div>
            </Link>
          </div>
        </Fade>

        <Fade right>
          <div className="col-lg-3 overview-service">
            <Link
              to={"/services/digital-marketing"}
              className="col-lg-3 overview-service"
              style={{
                backgroundImage:
                  "url(https://images.pexels.com/photos/590016/pexels-photo-590016.jpeg?auto=compress&cs=tinysrgb&w=800)",
              }}
            >
              <div className="overview-content">
                <h2>Digital Marketing</h2>
                <p></p>
              </div>
            </Link>
          </div>
        </Fade>
      </div>
    </div>
  );
}
