import React from "react";
import { Fade, Roll } from "react-reveal";
import { Link } from "react-router-dom";

import Title from "../../../../components/title/Title";
import "./Intro.css";

export default function Intro() {
  return (
    <div className="intro">
      <div className="container">
        <div className="row">
          <Fade left>
            <div className="intro-content col-lg-6 d-flex justify-content-evenly flex-column">
              <Title title4={"Introduction"} title2="Lumona" />
              <p>
                Welcome to my portfolio! I'm Lumona Mulengwa, a skilled software
                developer specializing in creating dynamic websites, web apps,
                mobile applications, and custom software solutions. With a
                strong background in programming and a passion for innovation, I
                help businesses and individuals bring their digital visions to
                life.
              </p>
              <h3>TECHNICAL SKILLS</h3>
              <ul>
                <li>
                  <span>Programming Languages:</span> Python, JavaScript, R,
                  Java, C, Solidity
                </li>
                <li>
                  <span>Web Development:</span> Flask, Django, Angular,
                  React.js, Node.js, 3.js
                </li>
                <li>
                  <span>Mobile Development:</span> React Native (Android & iOS)
                </li>
                <li>
                  <span>Cloud Technologies:</span> MongoDB, Google Cloud,
                  Firebase, AWS
                </li>
                <li>
                  <span>Database Management:</span> NoSQL
                </li>
                <li>
                  <span>Version Control:</span> Git, GitHub
                </li>
                <li>
                  <span>Other Technologies:</span> HTML,CSS, Wordpress, Wix
                </li>
              </ul>

              <p>Let's build expetional business together!!</p>

              <div className="row action-btns d-flex justify-content-center justify-content-lg-between">
                <Link to={"/services"} className="col-lg-3 action-btn">
                  Services Offered
                </Link>
                <Link to={"portfolio"} className="col-lg-3 action-btn">
                  Projects
                </Link>
                <Link to={"/about-me"} className="col-lg-3 action-btn">
                  More About Me
                </Link>
              </div>
            </div>
          </Fade>
          <Roll>
            <div className="intro-bg col-lg-6  d-flex justify-content-center align-items-center">
              <div className="intro-circle"></div>
            </div>
          </Roll>
        </div>
      </div>
    </div>
  );
}
