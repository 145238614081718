import React, { useState } from "react";
import "./HorizontalScroller.css";

const HorizontalScroller = ({ projects, isWebsites }) => {
  const [activeIndex, setActiveIndex] = useState(2); // Start with the 3rd div as the center (index 2)
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleClick = (index, link) => {
    if (activeIndex === index) {
      // If the clicked item is already active, redirect to the link
      window.open(link, "_blank");
    } else {
      // If the clicked item is not active, just set it as active
      setActiveIndex(index);
    }
  };

  const renderItems = () => {
    return projects.map((item, index) => (
      <div
        key={index}
        className={`item ${activeIndex === index ? "active" : ""} ${
          hoveredIndex === index && activeIndex !== index ? "hovered" : ""
        } ${isWebsites ? "website" : ""}`} // Fixed syntax to apply the class conditionally
        onMouseEnter={() => handleHover(index)}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleClick(index, item.link)}
        style={{ backgroundImage: `url(${item.img})` }}
      ></div>
    ));
  };

  const getTransform = () => {
    let offset = 0;
    const screenWidth = window.innerWidth; // Get screen width

    if (isWebsites) {
      const baseWidth = 280; // .item.website width
      const activeWidth = 400; // .item.active.website width
      const margin = 30; // Margin for .website items
      const totalWidth = baseWidth + margin; // Total width of each item with margin

      // Calculate offset to center the active item
      offset = activeIndex * -totalWidth + (screenWidth / 2 - activeWidth / 2);

      // Adjust for smaller screens
      if (screenWidth <= 768) {
        const adjustedActiveWidth = 300;
        const adjustedTotalWidth = 200 + margin; // Smaller total width for mobile
        offset =
          activeIndex * -adjustedTotalWidth +
          (screenWidth / 2 - adjustedActiveWidth / 2);
      }
      if (screenWidth <= 480) {
        const adjustedActiveWidth = 220;
        const adjustedTotalWidth = 150 + margin; // Even smaller total width for very small screens
        offset =
          activeIndex * -adjustedTotalWidth +
          (screenWidth / 2 - adjustedActiveWidth / 2);
      }
    } else {
      const baseWidth = 200; // .item width
      const activeWidth = 230; // .item.active width
      const margin = 10; // Margin for regular items
      const totalWidth = baseWidth + 2 * margin; // Total width of each item with margin

      // Calculate offset to center the active item
      offset = activeIndex * -totalWidth + (screenWidth / 2 - activeWidth / 2);

      // Adjust for smaller screens
      if (screenWidth <= 768) {
        const adjustedActiveWidth = 180;
        const adjustedTotalWidth = 150 + 2 * margin; // Smaller total width for mobile
        offset =
          activeIndex * -adjustedTotalWidth +
          (screenWidth / 2 - adjustedActiveWidth / 2);
      }
      if (screenWidth <= 480) {
        const adjustedActiveWidth = 130;
        const adjustedTotalWidth = 100 + 2 * margin; // Even smaller total width for very small screens
        offset =
          activeIndex * -adjustedTotalWidth +
          (screenWidth / 2 - adjustedActiveWidth / 2);
      }
    }

    return `translateX(${offset}px)`;
  };

  return (
    <div className="horizontal-container">
      <div className="wrapper" style={{ transform: getTransform() }}>
        {renderItems()}
      </div>
    </div>
  );
};

export default HorizontalScroller;
