import "./About.css";

import lumonaImage from "../../assets/images/lumona.png";

import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Fade, Flip, Roll, Rotate, Zoom } from "react-reveal";

export default function About() {
  return (
    <div className="lumona-bio">
      <div className="lumona-hero">
        <div className="opening-statements">
          <Fade down>
            <h2>Hi, I am Lumona</h2>
          </Fade>
          <Fade left>
            <p>
              I am a self-taught software developer with deep expertise in
              mobile app and web development, cloud technologies, and database
              management. With a passion for leveraging technology to drive
              innovation, I’ve built a diverse portfolio of projects spanning
              startups, global competitions, and social impact initiatives. My
              journey in tech has been shaped by curiosity, adaptability, and a
              commitment to learning, allowing me to lead and manage
              tech-focused teams with confidence.
            </p>
            <p>
              Since 2018, I’ve proudly represented Kenya at the Global Robotics
              Olympics (
              <a href="https://first.global/" target={"_blank"} rel="noreferer">
                First Global Challenge
              </a>
              ), both as a team member and a technical mentor. These experiences
              have honed my problem-solving skills and fueled my dedication to
              STEM education. Driven by the need to create tech awareness in
              Kenya, I’ve traveled to over 30 counties and several countries,
              sharing STEM expertise and inspiring high school students to
              embrace technology.
            </p>
            <p>
              In 2019, I founded{" "}
              <a
                href="https://electrixitaty.co/"
                target={"_blank"}
                rel="noreferer"
              >
                Electrixitaty
              </a>
              , a company aimed at educating and empowering young Kenyans with
              the skills and knowledge to harness the transformative potential
              of technology. Under this initiative, I’ve launched numerous
              projects and ventures that push the boundaries of innovation in
              areas like climate action and wildlife conservation.
            </p>

            <p>
              Whether it's developing cutting-edge platforms or mentoring the
              next generation of tech innovators, I’m always seeking new ways to
              use technology to make a positive impact. I’m currently open to
              opportunities in software or mobile app development that align
              with my passion for building meaningful, high-impact solutions.
            </p>
          </Fade>
          <Flip>
            <div className="socials">
              <a
                className="social link"
                target={"_blank"}
                rel="noreferrer"
                href="https://github.com/Lum-ona"
              >
                <GitHubIcon />
                <p>Github</p>
              </a>
              <a
                className="social link"
                target={"_blank"}
                rel="noreferrer"
                href="https://www.linkedin.com/in/lumona-mulengwa-117909165/"
              >
                <LinkedInIcon />
                <p>Linkedin</p>
              </a>
              <a
                className="social link"
                target={"_blank"}
                rel="noreferrer"
                href="https://www.instagram.com/lumona_mulengwa/"
              >
                <InstagramIcon />
                <p>Instagram</p>
              </a>
            </div>
          </Flip>
        </div>
        <Fade right>
          <div
            className="lumona-image"
            style={{ backgroundImage: `url(${lumonaImage})` }}
          ></div>
        </Fade>
      </div>
    </div>
  );
}
