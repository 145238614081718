import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/lumonaSignature.png";

export default function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [isServiceHovered, setIsServiceHovered] = useState(false);
  const [isConsultationHovered, setIsConsultationHovered] = useState(false);

  const { pathname } = useLocation();

  const homeRef = useRef();
  const aboutRef = useRef();
  const serviceRef = useRef();
  const portfolioRef = useRef();

  const recipientEmail = "lumona@electrixitaty.co";

  useEffect(() => {
    if (pathname.split("/").includes("about-me")) {
      aboutRef.current.classList.add("active");
      homeRef.current.classList.remove("active");
      serviceRef.current.classList.remove("active");
      portfolioRef.current.classList.remove("active");
    } else if (pathname.split("/").includes("servicesy")) {
      serviceRef.current.classList.add("active");
      homeRef.current.classList.remove("active");
      portfolioRef.current.classList.remove("active");
      aboutRef.current.classList.remove("active");
    } else if (pathname.split("/").includes("portfolio")) {
      portfolioRef.current.classList.add("active");
      homeRef.current.classList.remove("active");
      serviceRef.current.classList.remove("active");
      aboutRef.current.classList.remove("active");
    } else {
      homeRef.current.classList.add("active");
      serviceRef.current.classList.remove("active");
      portfolioRef.current.classList.remove("active");
      aboutRef.current.classList.remove("active");
    }
  }, [pathname]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const dropDownMouseIn = (e) => {
    e.currentTarget.classList.add("hovered");
  };

  const dropDownMouseLeave = (e) => {
    e.currentTarget.classList.remove("hovered");
  };

  const scrollToSection = (id) => {
    navigate("/");
    setTimeout(() => {
      const target = document.getElementById(id);
      if (target) {
        const targetRect = target.getBoundingClientRect();
        const offset = 0.15 * window.innerHeight;
        const scrollPosition = window.pageYOffset + targetRect.top - offset;

        window.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }
    }, 0);
  };

  return (
    <div className="header d-flex justify-content-between align-items-center">
      <div className="d-flex w-100 justify-content-between align-items-center">
        <Link to={"/"}>
          <img alt="Lumona Mulengwa" src={logo} className="logo" />
        </Link>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          <span className={`icon-bar ${isMobileMenuOpen ? "open" : ""}`}></span>
          <span className={`icon-bar ${isMobileMenuOpen ? "open" : ""}`}></span>
          <span className={`icon-bar ${isMobileMenuOpen ? "open" : ""}`}></span>
        </div>
        <nav className={`nav-menu ${isMobileMenuOpen ? "open" : ""}`}>
          <Link to="/" className="nav-option" ref={homeRef}>
            Home
          </Link>
          <div
            className="navs"
            onMouseEnter={(e) => dropDownMouseIn(e)}
            onMouseLeave={(e) => dropDownMouseLeave(e)}
          >
            <Link to={"/about-me"} className="nav-option" ref={aboutRef}>
              About Me
            </Link>
          </div>
          <div className="navs">
            <Link
              to={"/services"}
              className="nav-option"
              ref={serviceRef}
              onMouseEnter={(e) => {
                dropDownMouseIn(e);
                setIsServiceHovered(true);
              }}
              onMouseLeave={(e) => {
                dropDownMouseLeave(e);
                setIsServiceHovered(false);
              }}
            >
              Services Offered
            </Link>
            <div
              className={`drop-down ${isServiceHovered && "hovered"}`}
              onMouseEnter={(e) => {
                dropDownMouseIn(e);
                setIsServiceHovered(true);
              }}
              onMouseLeave={(e) => {
                dropDownMouseLeave(e);
                setIsServiceHovered(false);
              }}
            >
              <Link
                to="/services/website-development"
                className="drop-down-option"
              >
                Website Development
              </Link>
              <Link
                to="/services/mobile-development"
                className="drop-down-option"
              >
                Mobile Application
              </Link>
              <Link
                to="/services/machine-learning"
                className="drop-down-option"
              >
                Machine Learning
              </Link>
              <Link
                to="/services/digital-marketing"
                className="drop-down-option"
              >
                Digital Marketing
              </Link>
              <a
                href="https://www.coderhythm.electrixitaty.co"
                target={"_blank"}
                rel="norefere"
                className="drop-down-option"
              >
                Coding Education
              </a>
            </div>
          </div>
          <div
            className="navs"
            onMouseEnter={(e) => dropDownMouseIn(e)}
            onMouseLeave={(e) => dropDownMouseLeave(e)}
          >
            <Link to={"/portfolio"} className="nav-option" ref={portfolioRef}>
              My Portfolio
            </Link>
          </div>

          <div
            className="navs"
            onMouseEnter={(e) => {
              dropDownMouseIn(e);
              setIsConsultationHovered(true);
            }}
            onMouseLeave={(e) => {
              dropDownMouseLeave(e);
              setIsConsultationHovered(false);
            }}
          >
            <div
              className="nav-option"
              onMouseEnter={(e) => {
                dropDownMouseIn(e);
                setIsConsultationHovered(true);
              }}
              onMouseLeave={(e) => {
                dropDownMouseLeave(e);
                setIsConsultationHovered(false);
              }}
            >
              Let's Chat
            </div>
            <div
              className={`drop-down ${isConsultationHovered && "hovered"}`}
              onMouseEnter={(e) => {
                dropDownMouseIn(e);
                setIsConsultationHovered(true);
              }}
              onMouseLeave={(e) => {
                dropDownMouseLeave(e);
                setIsConsultationHovered(false);
              }}
            >
              <a
                href={`mailto:${recipientEmail}`}
                target={"_blank"}
                rel={"norefere"}
                className="drop-down-option"
              >
                Email
              </a>
              <a
                href="https://calendly.com/wansenziroz/custom-solution"
                target={"_blank"}
                rel={"norefere"}
                className="drop-down-option"
              >
                Calendly
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
