import React from "react";
import { Fade, Flip, Roll, Zoom } from "react-reveal";
import HorizontalScroller from "../../components/tester/HorizontalScroller";
import Title from "../../components/title/Title";
import "./Portfolio.css";
import elec from "../../assets/images/websites/electrixitaty.png";
import hairyblo from "../../assets/images/websites/hairyblo.png";
import wildnow from "../../assets/images/websites/wildnow.png";
import gokijany from "../../assets/images/websites/gokijany.png";
import vibes from "../../assets/images/websites/vibes.png";
import ethe from "../../assets/images/websites/ethe.png";
import rudi from "../../assets/images/websites/rudi.png";
import izosphere from "../../assets/images/websites/izosphere.png";
import lema from "../../assets/images/websites/lema.png";
import baye from "../../assets/images/apps/baye.png";
import gky from "../../assets/images/apps/gky.png";

export default function Portfolio() {
  const Websites = [
    { img: hairyblo, link: "https://hairyblo.vercel.app/" },
    { img: izosphere, link: "https://izosphermusic.vercel.app/" },
    { img: gokijany, link: "http://gokijany.electrixitaty.co/" },
    { img: elec, link: "https://electrixitaty.co/" },
    { img: lema, link: "https://lemainsurance.com/" },
    { img: vibes, link: "https://vibesandvent.vercel.app/" },
    { img: ethe, link: "http://ethe.co.ke/" },
    { img: rudi, link: "https://rudi-2d-web.vercel.app/" },
    { img: wildnow, link: "https://wildnow.africa/" },
  ];
  const Apps = [
    {
      img: gky,
      link: "https://play.google.com/store/apps/details?id=com.electrixitaty.gokijany",
    },
    {
      img: baye,
      link: "https://play.google.com/store/apps/details?id=com.electrixitaty.bayehfest",
    },
  ];
  return (
    <div className="portfolio">
      <div className="container">
        <div className="portfolio-overview d-flex justify-content-center align-items-center flex-column text-center ">
          <Fade down>
            <div className="portfolio-title">
              <Title title2={"Projects I have done"} title4={"portfolio"} />
              <p>
                Welcome to my portfolio! Here, you’ll find a collection of
                projects that showcase my skills in web development, mobile app
                creation, and custom software solutions. Each project highlights
                my commitment to innovation and quality, spanning various
                industries and technologies. Explore my work to see how I’ve
                helped clients bring their digital visions to life, and feel
                free to reach out if you’re interested in collaborating on a new
                project.
              </p>
            </div>
          </Fade>
        </div>
        <div className="portfolio-projects">
          <div className="portfolio-project">
            <h4 style={{ marginTop: "5vh" }}>Web Development:</h4>
            <HorizontalScroller projects={Websites} isWebsites={true} />
          </div>
          <div className="portfolio-project">
            <h4 style={{ marginTop: "10vh" }}>App Development:</h4>
            <HorizontalScroller projects={Apps} />
          </div>
        </div>
      </div>
    </div>
  );
}
