import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Portfolio from "./pages/portfolio/Portfolio";
import Header from "./components/header/Header";
import Services from "./pages/services/Services";
import Footer from "./components/footer/Footer";
import { useEffect, useState } from "react";
import Preloader from "./components/preloader/Preloader";

function App() {
  function ScrollToTopOnRouteChange() {
    const location = useLocation();
    useEffect(() => {
      if (location.pathname !== "/") {
        window.scrollTo(0, 0);
      }
    }, [location]);
    return null;
  }

  const [loading, setLoading] = useState(true); // State to manage the loading status

  useEffect(() => {
    // Simulate loading process
    setTimeout(() => {
      setLoading(false); // Set loading to false after 2 seconds (simulate data fetching)
    }, 2000);
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        {loading ? (
          <Preloader />
        ) : (
          <>
            <Header />
            <ScrollToTopOnRouteChange />
            <Routes>
              <Route index path="/" element={<Home />} />
              <Route path="/about-me" element={<About />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/services/:id?" element={<Services />} />
            </Routes>
            <Footer />
          </>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
