import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BaseServiceScreen from "./BaseServiceScreen";
import "./Services.css";
import Title from "../../components/title/Title";
import { Fade, Zoom } from "react-reveal";
import { Link } from "react-router-dom";

export default function Services() {
  const { pathname } = useLocation();
  const [lastSegment, setLastSegment] = useState(null);

  // Split the path into segments
  const pathSegments = pathname.split("/").filter(Boolean);
  useEffect(() => {
    setLastSegment(pathSegments[pathSegments.length - 1]);
  }, [pathSegments]);

  if (lastSegment === null) {
    return;
  }

  const serviceCategory = ({ route, tag1, tag2, title, bgImg }) => (
    <Zoom>
      <div className="service-category col-lg-6 d-flex justify-content-between flex-column">
        <Link to={`/services/${route}`} className="link-container">
          <div className="d-flex tags justify-content-end">
            <div className="">{tag1}</div>
            <div className="">{tag2}</div>
          </div>
          <div className="service-category-title">
            <h3>{title}</h3>
          </div>
          <div
            className="service-category-bg-img"
            style={{ backgroundImage: `url(${bgImg})` }}
          ></div>
        </Link>
      </div>
    </Zoom>
  );

  return (
    <div className="mt-4">
      {lastSegment === "services" ? (
        <div className="services ">
          <div className="container">
            <div className="service-overview d-flex justify-content-center align-items-center flex-column text-center ">
              <Fade down>
                <div>
                  <Title title2={"Services I Offer"} title4={"Services"} />
                  <p>
                    Discover the range of services I offer, designed to meet the
                    diverse needs of businesses and individuals in today's
                    digital landscape. Whether you're looking for a simple
                    website, a complex web application, a mobile app, or a
                    custom software solution, I have the expertise to bring your
                    vision to life. Each service category is tailored to provide
                    innovative, high-quality solutions that drive growth and
                    engagement.
                  </p>
                </div>
              </Fade>
            </div>
            <div className="service-categories">
              <div className="row d-flex justify-content-evenly">
                {serviceCategory({
                  route: "website-development",
                  tag1: "Html, React js",
                  tag2: "Web development",
                  title: "Website and Website Application Developments",
                  bgImg:
                    "https://images.pexels.com/photos/326519/pexels-photo-326519.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
                })}
                {serviceCategory({
                  route: "mobile-development",
                  tag1: "IOS/Android, React Native",
                  tag2: "App development",
                  title: "Mobile Application Developments",
                  bgImg:
                    "https://images.pexels.com/photos/38544/imac-apple-mockup-app-38544.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
                })}
                {serviceCategory({
                  route: "machine-learning",
                  tag1: "Python, Tensorflow",
                  tag2: "Machine Learning",
                  title: "Machine learning algorithms and softwares",
                  bgImg:
                    "https://images.pexels.com/photos/177598/pexels-photo-177598.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
                })}
                {serviceCategory({
                  route: "digital-marketing",
                  tag1: "SEO, Social Media, Ads",
                  tag2: "Digital Marketing",
                  title: "Search Engine Optimisation via digital marketing",
                  bgImg:
                    "https://images.pexels.com/photos/669617/pexels-photo-669617.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <BaseServiceScreen />
      )}
    </div>
  );
}
