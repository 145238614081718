import React from "react";
import "./Home.css";
import Featured from "./sections/featured/Featured";
import Hero from "./sections/hero/Hero";
import Intro from "./sections/intro/Intro";
import Overview from "./sections/overview/Overview";

export default function Home() {
  return (
    <div className="home">
      <Hero />
      <Intro />
      <Overview />
      <Featured />
    </div>
  );
}
