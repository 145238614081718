import React from "react";
import "./Footer.css";
import logo from "../../assets/images/lumonaSignature.png";

export default function Footer() {
  return (
    <footer className="d-flex align-items-center flex-column justify-content-center">
      <div className="container d-flex justify-content-center">
        <div className="logo text-center">
          <img src={logo} alt="" />
        </div>
      </div>
      <div className="footer_footer pt-4">
        <p>© 2024 Electrixitaty | All Rights Reserved</p>
      </div>
    </footer>
  );
}
